
import Analytics from "views/Pages/Analytics";
import Login from "views/Pages/Login.js";
import RevenueSystem from "views/Pages/RevenueSystem";
import SingleChapter from "views/Pages/SingleChapter";
import AddEditArticle from "views/Pages/AddEditArticle";
import Articles from "views/Pages/ViewArticles";
import Chapters from "views/Pages/ViewChapters";
import ViewGenres from "views/Pages/ViewGenres";
import ViewNotifications from "views/Pages/ViewNotifications";
import ViewTags from "views/Pages/ViewTags";
import ViewUsers from "views/Pages/ViewUsers";
import ViewPayments from "views/Pages/ViewPayments";
import AddEditChapters from "views/Pages/AddEditChapter";
import ChapterAccess from "views/Pages/ChapterAccess";
import UserCredit from "views/Pages/UserCredit";
import AdminActivity from "views/Pages/AdminActivity";
import Voucher from "views/Pages/Voucher";
import ViewAnalytics from "views/Pages/ViewAnalytics";
import VoucherUsageReport from "views/Pages/VoucherUsageReport";

const role = JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role;

if (role == "editor") {
  var routes = [

    {
      path: "/analytics",
      name: "Analytics",
      icon: "fa fa-chart-pie text-red",
      component: Analytics,
      layout: "/admin",
    },
    {
      path: "/novels/:id",
      name: "Chapters",
      icon: "fa fa-file-lines text-red",
      component: Chapters,
      hideInSidebar: true,
      layout: "/admin",
    },

    {
      path: "/novels",
      name: "Novels",
      icon: "fa fa-book text-red",
      component: Articles,
      layout: "/admin",
    },
    {
      path: "/novel/create",
      name: "Create Novel",
      icon: "fa fa-book text-red",
      component: AddEditArticle,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/article/edit/:slug",
      name: "Edit Novel",
      icon: "fa fa-book text-red",
      component: AddEditArticle,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/article/:slug/chapters",
      name: "Edit Novel",
      icon: "fa fa-book text-red",
      component: AddEditChapters,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/chapters",
      name: "Chapters",
      icon: "fa fa-file-alt text-red",
      component: Chapters,
      layout: "/admin",
    },
    {
      path: "/genres",
      name: "Genres",
      icon: "fa fa-smile-wink text-red",
      component: ViewGenres,
      layout: "/admin",
    },
    {
      path: "/tags",
      name: "Tag",
      icon: "fa fa-tags text-red",
      component: ViewTags,
      layout: "/admin",
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: "fa fa-bell text-red",
      component: ViewNotifications,
      layout: "/admin",
    },
    {
      path: "/users",
      name: "Users",
      icon: "fa fa-user text-red",
      component: ViewUsers,
      layout: "/admin",
    },
    // {
    //   path: "/payment-history",
    //   name: "Payment History",
    //   icon: "fa fa-coins text-red",
    //   component: ViewPayments,
    //   layout: "/admin",
    // },
    {
      path: "/revenue",
      name: "Revenue System",
      icon: "fa fa-chart-pie text-red",
      component: RevenueSystem,
      layout: "/admin",
    },
    {
      path: "/chapter/:slug",
      name: "Chapter",
      component: SingleChapter,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/novel/:articleSlug/chapter/:slug",
      name: "Chapter",
      component: SingleChapter,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/login",
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: Login,
      hideInSidebar: true,
      layout: "/auth",
    },
    ,


  ];
} else if (role == "finance") {
  var routes = [

    {
      path: "/analytics",
      name: "Analytics",
      icon: "fa fa-chart-pie text-red",
      component: Analytics,
      layout: "/admin",
    },
    {
      path: "/novels/:id",
      name: "Chapters",
      icon: "fa fa-file-lines text-red",
      component: Chapters,
      hideInSidebar: true,
      layout: "/admin",
    },

    {
      path: "/novels",
      name: "Novels",
      icon: "fa fa-book text-red",
      component: Articles,
      layout: "/admin",
    },
    {
      path: "/novel/create",
      name: "Create Novel",
      icon: "fa fa-book text-red",
      component: AddEditArticle,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/article/edit/:slug",
      name: "Edit Novel",
      icon: "fa fa-book text-red",
      component: AddEditArticle,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/article/:slug/chapters",
      name: "Edit Novel",
      icon: "fa fa-book text-red",
      component: AddEditChapters,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/chapters",
      name: "Chapters",
      icon: "fa fa-file-alt text-red",
      component: Chapters,
      layout: "/admin",
    },
    {
      path: "/genres",
      name: "Genres",
      icon: "fa fa-smile-wink text-red",
      component: ViewGenres,
      layout: "/admin",
    },
    {
      path: "/tags",
      name: "Tag",
      icon: "fa fa-tags text-red",
      component: ViewTags,
      layout: "/admin",
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: "fa fa-bell text-red",
      component: ViewNotifications,
      layout: "/admin",
    },
    {
      path: "/users",
      name: "Users",
      icon: "fa fa-user text-red",
      component: ViewUsers,
      layout: "/admin",
    },
    {
      path: "/credits",
      name: "User Credit",
      icon: "fa fa-coins text-red",
      component: UserCredit,
      layout: "/admin",
    },
    {
      path: "/payment-history",
      name: "Payment History",
      icon: "fa fa-coins text-red",
      component: ViewPayments,
      layout: "/admin",
    },
    {
      path: "/revenue",
      name: "Revenue System",
      icon: "fa fa-chart-pie text-red",
      component: RevenueSystem,
      layout: "/admin",
    },
    {
      path: "/chapter/:slug",
      name: "Chapter",
      component: SingleChapter,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/novel/:articleSlug/chapter/:slug",
      name: "Chapter",
      component: SingleChapter,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/login",
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: Login,
      hideInSidebar: true,
      layout: "/auth",
    },
    ,


  ];
} else if (role == "superadmin") {
  var routes = [

    {
      path: "/analytics",
      name: "Analytics",
      icon: "fa fa-chart-pie text-red",
      component: Analytics,
      layout: "/admin",
    },
    {
      path: "/novels/:id",
      name: "Chapters",
      icon: "fa fa-file-lines text-red",
      component: Chapters,
      hideInSidebar: true,
      layout: "/admin",
    },

    {
      path: "/novels",
      name: "Novels",
      icon: "fa fa-book text-red",
      component: Articles,
      layout: "/admin",
    },
    {
      path: "/novel/create",
      name: "Create Novel",
      icon: "fa fa-book text-red",
      component: AddEditArticle,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/article/edit/:slug",
      name: "Edit Novel",
      icon: "fa fa-book text-red",
      component: AddEditArticle,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/article/:slug/chapters",
      name: "Edit Novel",
      icon: "fa fa-book text-red",
      component: AddEditChapters,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/chapters",
      name: "Chapters",
      icon: "fa fa-file-alt text-red",
      component: Chapters,
      layout: "/admin",
    },
    {
      path: "/chapters-analytics",
      name: "Chapters Analytics",
      icon: "fa fa-file-alt text-red",
      component: ViewAnalytics,
      layout: "/admin",
    },
    {
      path: "/genres",
      name: "Genres",
      icon: "fa fa-smile-wink text-red",
      component: ViewGenres,
      layout: "/admin",
    },
    {
      path: "/tags",
      name: "Tag",
      icon: "fa fa-tags text-red",
      component: ViewTags,
      layout: "/admin",
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: "fa fa-bell text-red",
      component: ViewNotifications,
      layout: "/admin",
    },
    {
      path: "/activity",
      name: "Admin Activiity",
      icon: "fa fa-flag text-red",
      component: AdminActivity,
      layout: "/admin",
    },
    {
      path: "/users",
      name: "Users",
      icon: "fa fa-user text-red",
      component: ViewUsers,
      layout: "/admin",
    },
    {
      path: "/credits",
      name: "User Credit",
      icon: "fa fa-coins text-red",
      component: UserCredit,
      layout: "/admin",
    },
    {
      path: "/payment-history",
      name: "Payment History",
      icon: "fa fa-coins text-red",
      component: ViewPayments,
      layout: "/admin",
    },
    {
      path: "/revenue",
      name: "Revenue System",
      icon: "fa fa-chart-pie text-red",
      component: RevenueSystem,
      layout: "/admin",
    },
    {
      path: "/voucher",
      name: "Vouchers",
      icon: "fa fa-gift text-red",
      component: Voucher,
      layout: "/admin",
    },
    {
      path: "/voucher-usage",
      name: "Voucher Usage Report",
      icon: "fa fa-file-alt text-red",
      component: VoucherUsageReport,
      layout: "/admin",
    },
    {
      path: "/chapter/:slug",
      name: "Chapter",
      component: SingleChapter,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/novel/:articleSlug/chapter/:slug",
      name: "Chapter",
      component: SingleChapter,
      hideInSidebar: true,
      layout: "/admin",
    },
    {
      path: "/chapter-access",
      name: "Settings",
      icon: "fa fa-cog text-red",
      component: ChapterAccess,
      layout: "/admin",
    },
    // {
    //   path: "/bookings",
    //   name: "Bookings",
    //   icon: "fa fa-file-alt text-red",
    //   component: Bookings,
    //   layout: "/admin",
    // },
    {
      path: "/login",
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: Login,
      hideInSidebar: true,
      layout: "/auth",
    },

  ];
} else {
  var routes = [
    {
      path: "/login",
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: Login,
      hideInSidebar: true,
      layout: "/auth",
    },
  ]
}
export default routes;
