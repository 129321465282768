import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from "moment";
import { toastMessage } from 'Helper/Toaster';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';

const Voucher = () => {
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [selectedVoucher, setSelectedVoucher] = useState(null);

    const validationSchema = Yup.object().shape({
        voucher_code: Yup.string()
            .min(6, 'Minimum 6 characters required')
            .max(16, 'Maximum 16 characters allowed')
            .required('Voucher code is required'),
        discounted_percentage: Yup.number()
            .min(1, 'Minimum discount is 1%')
            .max(100, 'Maximum discount is 100%')
            .required('Discount percentage is required'),
        start_period: Yup.date()
            .required('Start date is required'),
        end_period: Yup.date()
            .required('End date is required')
            .min(Yup.ref('start_period'), 'End date must be after start date'),
        usage_limit: Yup.number()
            .min(1, 'Minimum usage limit is 1')
            .required('Usage limit is required'),
        auto_claim: Yup.boolean().required('Auto claim option is required')
    });

    const formatDateTimeForInput = (dateString) => {
        if (!dateString) return '';
        return moment(dateString).format('YYYY-MM-DDTHH:mm');
    };

    const getInitialValues = () => {
        if (modalType === 'edit' && selectedVoucher) {
            return {
                voucher_code: selectedVoucher.voucher_code || '',
                discounted_percentage: selectedVoucher.discounted_percentage || 0,
                start_period: formatDateTimeForInput(selectedVoucher.start_period),
                end_period: formatDateTimeForInput(selectedVoucher.end_period),
                usage_limit: selectedVoucher.usage_limit || 1,
                auto_claim: selectedVoucher.auto_claim?.toString() || 'false'
            };
        }
        return {
            voucher_code: '',
            discounted_percentage: 0,
            start_period: '',
            end_period: '',
            usage_limit: 1,
            auto_claim: 'false'
        };
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const formattedValues = {
                ...values,
                auto_claim: values.auto_claim === 'true',
                start_period: moment(values.start_period).toISOString(),
                end_period: moment(values.end_period).toISOString(),
            };

            if (modalType === 'create') {
                await api.headers().method('post', '/voucher/create', formattedValues);
                toastMessage.success('Voucher created successfully');
            } else if (modalType === 'edit') {
                await api.headers().method('put', `/voucher/update/${selectedVoucher._id}`, formattedValues);
                toastMessage.success('Voucher updated successfully');
            }
            handleCloseModal();
            fetchVouchers();
        } catch (error) {
            toastMessage.failure(error?.response?.data?.message || 'Operation failed');
        } finally {
            setSubmitting(false);
        }
    };

    const handleDelete = async (voucherId) => {
        try {
            await api.headers().method('delete', `/voucher/delete/${voucherId}`);
            toastMessage.success('Voucher deleted successfully');
            fetchVouchers();
        } catch (error) {
            toastMessage.failure(error?.response?.data?.message || 'Delete operation failed');
        }
    };

    const handleOpenModal = (type, voucher = null) => {
        setModalType(type);
        setSelectedVoucher(voucher);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalType('');
        setSelectedVoucher(null);
    };

    const formatVoucherData = (voucherList) => {
        return voucherList.map((voucher, index) => ({
            ...voucher,
            'id': voucher._id,
            's.no': index + 1,
            'Code': voucher.voucher_code,
            'Discount': `${voucher.discounted_percentage}%`,
            'Usage': `${voucher.current_usage}/${voucher.usage_limit}`,
            'Period': `${moment(voucher.start_period).format('L')} - ${moment(voucher.end_period).format('L')}`,
            'Status': moment().isBetween(voucher.start_period, voucher.end_period) ?
                'Active' : moment().isAfter(voucher.end_period) ?
                    'Expired' : 'Upcoming',
            'actions': (
                <UncontrolledDropdown>
                    <DropdownToggle
                        className="btn-icon-only text-light"
                        role="button"
                        size="sm"
                        color=""
                        onClick={(e) => e.preventDefault()}
                    >
                        <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem onClick={() => handleOpenModal('edit', voucher)}>
                            Edit Voucher
                        </DropdownItem>
                        <DropdownItem
                            className="text-danger"
                            onClick={() => handleDelete(voucher._id)}
                        >
                            Delete Voucher
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }));
    };

    const fetchVouchers = async () => {
        try {
            setLoading(true);
            const response = await api.headers().method('get', '/voucher/list');
            const formattedData = formatVoucherData(response.data.vouchers);
            setVouchers(formattedData);
        } catch (error) {
            console.error('Error fetching vouchers:', error);
            toastMessage.failure('Failed to fetch vouchers');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVouchers();
    }, []);

    const columns = [
        {
            dataField: 's.no',
            text: '#',
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'Code',
            text: 'Voucher Code',
            headerStyle: { width: '15%' }
        },
        {
            dataField: 'Discount',
            text: 'Discount',
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'Usage',
            text: 'Usage',
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'Period',
            text: 'Valid Period',
            headerStyle: { width: '25%' }
        },
        {
            dataField: 'Status',
            text: 'Status',
            headerStyle: { width: '10%' },
            formatter: (cell) => (
                <span className={`badge badge-${cell === 'Active' ? 'success' :
                    cell === 'Expired' ? 'danger' : 'warning'
                    }`}>
                    {cell}
                </span>
            )
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerStyle: { width: '10%' }
        }
    ];

    // Custom styles for the select field
    const selectStyles = {
        select: {
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            padding: '0.375rem 0.75rem',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
            color: '#212529',
            backgroundColor: '#fff',
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            height: '38px',
            width: '100%',
            cursor: 'pointer',
            backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 0.75rem center',
            backgroundSize: '16px 12px',
        },
        wrapper: {
            position: 'relative',
            width: '100%',
        }
    };

    return (
        <>
            <div>
                <Header />
                <Container className="mt--7" fluid>
                    <Row className="mt-5">
                        <div className="col">
                            <Card className="bg-default shadow">
                                <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
                                    <h3 className="text-white mb-0">Vouchers</h3>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleOpenModal('create')}
                                    >
                                        Create Voucher
                                    </Button>
                                </CardHeader>
                                <BootstrapTable
                                    bootstrap4
                                    bordered={false}
                                    headerClasses="thead-dark"
                                    bodyClasses="text-light"
                                    wrapperClasses="table-responsive table-dark"
                                    keyField="id"
                                    data={vouchers}
                                    columns={columns}
                                    noDataIndication={() => (
                                        <div className="text-center text-muted py-4">
                                            {loading ? 'Loading...' : 'No vouchers found'}
                                        </div>
                                    )}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>

            {/* Voucher Modal */}
            <Modal show={showModal} onHide={handleCloseModal} size="md">
                <Modal.Header>
                    <Modal.Title>{modalType === 'create' ? 'Create Voucher' : 'Edit Voucher'}</Modal.Title>
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={handleCloseModal}
                        style={{
                            textDecoration: 'none',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: '#dc3545',
                            padding: '0 8px'
                        }}
                    >
                        <i class="fas fa-times text-danger"></i>
                    </button>
                </Modal.Header>
                <Formik
                    initialValues={getInitialValues()}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, isSubmitting, touched, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <div className="mb-4 col-12">
                                        <Form.Label className="text-muted fw-normal">Voucher Code</Form.Label>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="voucher_code"
                                            placeholder="Enter voucher code"
                                        />
                                        {touched.voucher_code && errors.voucher_code && (
                                            <div className="text-danger mt-1 small">{errors.voucher_code}</div>
                                        )}
                                    </div>

                                    <div className="mb-4 col-12">
                                        <Form.Label className="text-muted fw-normal">Discount Percentage</Form.Label>
                                        <Field
                                            className="form-control"
                                            type="number"
                                            name="discounted_percentage"
                                            placeholder="0"
                                        />
                                        {touched.discounted_percentage && errors.discounted_percentage && (
                                            <div className="text-danger mt-1 small">{errors.discounted_percentage}</div>
                                        )}
                                    </div>

                                    <div className="mb-4 col-12">
                                        <Form.Label className="text-muted fw-normal">Start Date</Form.Label>
                                        <Field
                                            className="form-control"
                                            type="datetime-local"
                                            name="start_period"
                                        />
                                        {touched.start_period && errors.start_period && (
                                            <div className="text-danger mt-1 small">{errors.start_period}</div>
                                        )}
                                    </div>

                                    <div className="mb-4 col-12">
                                        <Form.Label className="text-muted fw-normal">End Date</Form.Label>
                                        <Field
                                            className="form-control"
                                            type="datetime-local"
                                            name="end_period"
                                        />
                                        {touched.end_period && errors.end_period && (
                                            <div className="text-danger mt-1 small">{errors.end_period}</div>
                                        )}
                                    </div>

                                    <div className="mb-4 col-12">
                                        <Form.Label className="text-muted fw-normal">Usage Limit</Form.Label>
                                        <Field
                                            className="form-control"
                                            type="number"
                                            name="usage_limit"
                                            placeholder="1"
                                        />
                                        {touched.usage_limit && errors.usage_limit && (
                                            <div className="text-danger mt-1 small">{errors.usage_limit}</div>
                                        )}
                                    </div>

                                    <div className="mb-4 col-12">
                                        <Form.Label className="text-muted fw-normal">Auto Claim</Form.Label>
                                        <div style={selectStyles.wrapper}>
                                            <Field
                                                as="select"
                                                name="auto_claim"
                                                style={selectStyles.select}
                                            >
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </Field>
                                        </div>
                                        {touched.auto_claim && errors.auto_claim && (
                                            <div className="text-danger mt-1 small">{errors.auto_claim}</div>
                                        )}
                                    </div>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={handleCloseModal}
                                    className="px-4"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="px-4"
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default Voucher;