import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Form, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { CardHeader } from 'reactstrap';
import moment from 'moment';
import { api } from 'Interceptor';
import { toastMessage } from 'Helper/Toaster';
import Header from 'components/Headers/Header';

const VoucherUsageReport = () => {
    const [usageData, setUsageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        voucher_code: '',
        userId: ''
    });

    const columns = [
        {
            dataField: 'index',
            text: '#',
            formatter: (cell, row, rowIndex) => rowIndex + 1,
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'voucher.code',
            text: 'Voucher Code',
            headerStyle: { width: '15%' }
        },
        {
            dataField: 'voucher.details.discounted_percentage',
            text: 'Discount (%)',
            headerStyle: { width: '10%' },
            formatter: (cell) => cell ? `${cell}%` : 'N/A'
        },
        {
            dataField: 'user.fullName',
            text: 'User Name',
            headerStyle: { width: '20%' }
        },
        {
            dataField: 'user.email',
            text: 'Email',
            headerStyle: { width: '20%' }
        },
        {
            dataField: 'usage_time',
            text: 'Usage Time',
            headerStyle: { width: '20%' },
            formatter: (cell) => moment(cell).format('LLL')
        },
        {
            dataField: 'voucher.details.end_period',
            text: 'Expires',
            headerStyle: { width: '10%' },
            formatter: (cell) => cell ? moment(cell).format('L') : 'N/A'
        }
    ];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const fetchUsageReport = async (exportData = false) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();

            // Only add filters if they have values
            if (filters.startDate) queryParams.append('startDate', filters.startDate);
            if (filters.endDate) queryParams.append('endDate', filters.endDate);
            if (filters.voucher_code) queryParams.append('voucher_code', filters.voucher_code);
            if (filters.userId) queryParams.append('userId', filters.userId);
            if (exportData) queryParams.append('export', 'true');

            const url = `/voucher/usage-report?${queryParams.toString()}`;

            if (exportData) {
                const response = await api.headers().method('get', url, { responseType: 'blob' });
                const blob = new Blob([response.data], { type: 'text/csv' });
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = `voucher-usage-report-${moment().format('YYYY-MM-DD')}.csv`;
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(downloadUrl); // Clean up
            } else {
                const response = await api.headers().method('get', url);
                setUsageData(response.data.usageData);
            }
        } catch (error) {
            toastMessage.failure(error?.response?.data?.message || 'Failed to fetch usage report');
        } finally {
            setLoading(false);
        }
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        fetchUsageReport();
    };

    // Reset filters
    const handleReset = () => {
        setFilters({
            startDate: '',
            endDate: '',
            voucher_code: '',
            userId: ''
        });
        // Fetch without filters
        fetchUsageReport();
    };

    useEffect(() => {
        fetchUsageReport();
    }, []);

    return (
        <div>
            <Header />
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <div className="col">
                        <Card className="bg-default shadow">
                            <CardHeader className="bg-transparent border-0">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h3 className="text-white mb-0">Voucher Usage Report</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            variant="success"
                                            className="mr-2"
                                            onClick={() => fetchUsageReport(true)}
                                            disabled={loading}
                                        >
                                            Export CSV
                                        </Button>
                                    </div>
                                </div>
                            </CardHeader>

                            <Card.Body className="bg-default">
                                <Form onSubmit={handleFilterSubmit}>
                                    <Row className="mb-4">
                                        <div className="col-md-3">
                                            <Form.Group>
                                                <Form.Label className="text-white">Start Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="startDate"
                                                    value={filters.startDate}
                                                    onChange={handleFilterChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group>
                                                <Form.Label className="text-white">End Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="endDate"
                                                    value={filters.endDate}
                                                    onChange={handleFilterChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group>
                                                <Form.Label className="text-white">Voucher Code</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="voucher_code"
                                                    value={filters.voucher_code}
                                                    onChange={handleFilterChange}
                                                    placeholder="Enter voucher code"
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group>
                                                <Form.Label className="text-white">User ID</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="userId"
                                                    value={filters.userId}
                                                    onChange={handleFilterChange}
                                                    placeholder="Enter user ID"
                                                />
                                            </Form.Group>
                                        </div>
                                    </Row>

                                    <Row className="mb-4">
                                        <div className="col text-right">
                                            <Button
                                                variant="secondary"
                                                onClick={handleReset}
                                                disabled={loading}
                                                className="mr-2"
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                Apply Filters
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>

                                <BootstrapTable
                                    bootstrap4
                                    bordered={false}
                                    headerClasses="thead-dark"
                                    bodyClasses="text-light"
                                    wrapperClasses="table-responsive table-dark"
                                    keyField="_id"
                                    data={usageData}
                                    columns={columns}
                                    noDataIndication={() => (
                                        <div className="text-center text-muted py-4">
                                            {loading ? 'Loading...' : 'No usage data found'}
                                        </div>
                                    )}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default VoucherUsageReport;